
import './App.css';
import Counterparent from "./counterparent";
import Counterchild from "./Counterchild" 



function App() {
  return (
    <div >
      <Counterparent/>
    </div>
  );
}

export default App;
